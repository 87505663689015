import { css } from '@emotion/core';

import theme from './theme';
import proximaNovaSoftRegular from '~theme/fonts/ProximaNovaSoft-Regular.otf';
import proximaNovaSoftMedium from '~theme/fonts/ProximaNovaSoft-Medium.otf';
import proximaNovaSoftBold from '~theme/fonts/ProximaNovaSoft-Bold.otf';

/**
 * Custom global styles for entire application
 */
export default css`
  :root {
    --base-font-size: 16px;
    --base-font: 'Proxima Nova Soft';
    --base-medium-font: 'Proxima Nova Medium';

    --primary: ${theme.colors.lightPurple};
    --primary-05: ${theme.colors.lightPurple05};
    --primary-15: ${theme.colors.lightPurple15};
    --primary-50: ${theme.colors.lightPurple50};
    --purple: ${theme.colors.purple};
    --dark-grey-blue: ${theme.colors.darkGreyBlue};
    --dark-grey-blue-20: ${theme.colors.darkGreyBlue20};
    --dark-grey-blue-50: ${theme.colors.darkGreyBlue50};
    --gray-background: ${theme.colors.grayBackground};
    --yellow: ${theme.colors.yellow};
    --red: ${theme.colors.red};
    --red-pink: ${theme.colors.redPink};
    --green: ${theme.colors.green};
    --white: ${theme.colors.white};
    --light-gray: ${theme.colors.lightGray};
    --gray: ${theme.colors.gray};
    --borderColor: ${theme.colors.borderColor};
    --cornflower: ${theme.colors.cornflower};
    --light-blue: ${theme.colors.lightBlue};
    --grey-blue: ${theme.colors.greyBlue};
    --border-color: ${theme.colors.borderColor};
    --lighter-font: ${theme.fontWeight.light};
    --medium-font: ${theme.fontWeight.medium};
    --bolder-font: ${theme.fontWeight.bolder};
    --font-x-small: ${theme.fontSize.xSmall};
    --font-small: ${theme.fontSize.small};
    --font-medium: ${theme.fontSize.medium};
    --font-large: ${theme.fontSize.large};
    --font-x-large: ${theme.fontSize.xLarge};
    --space-x-small: ${theme.spacing.xSmall};
    --space-small: ${theme.spacing.small};
    --space-medium: ${theme.spacing.medium};
    --space-large: ${theme.spacing.large};
    --space-x-large: ${theme.spacing.xLarge};
    --border-radius-standard: ${theme.borderRadius.standard};
    --border-radius-pill: ${theme.borderRadius.pill};
    --border-width-small: ${theme.borderWidth.small};
    --border-width-medium: ${theme.borderWidth.medium};
    --border-width-large: ${theme.borderWidth.large};
  }

  @font-face {
    font-family: 'Proxima Nova Soft';
    font-weight: normal;
    src: url('${proximaNovaSoftRegular}') format('opentype');
  }

  @font-face {
    font-family: 'Proxima Nova Soft';
    font-weight: 500;
    src: url('${proximaNovaSoftRegular}') format('opentype');
  }

  @font-face {
    font-family: 'Proxima Nova Soft';
    font-weight: 700;
    src: url('${proximaNovaSoftMedium}') format('opentype');
  }

  @font-face {
    font-family: 'Proxima Nova Soft';
    font-weight: 700;
    src: url('${proximaNovaSoftMedium}') format('opentype');
  }

  @font-face {
    font-family: 'Proxima Nova Soft';
    font-weight: 900;
    src: url('${proximaNovaSoftBold}') format('opentype');
  }

  @font-face {
    font-family: 'Proxima Nova Medium';
    font-weight: 500;
    src: url('${proximaNovaSoftMedium}') format('opentype');
  }

  html,
  body {
    font-family: var(--base-font);
    font-size: var(--base-font-size);
    font-weight: normal;

    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
  }

  b {
    font-weight: 900;
  }
`;
