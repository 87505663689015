import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Global } from '@emotion/core';

import Layout from '~components/Layout';
import Loading from '~pages/Loading';
import UnavailableMobile from '~pages/UnavailableMobile';
import globalStyle from '~theme/globalStyle';
import amplitude from '~utils/amplitude';
import { isMobile } from 'react-device-detect';
/**
 * React router switcher
 */
const WithRouter = ({ children }) => (
  <Router>
    <Switch>{children}</Switch>
  </Router>
);

/**
 * App
 * Application entry point
 * @returns {*} React Component
 */
const App = () => {
  useEffect(() => {
    if (isMobile) {
      amplitude.logEvent('Demo mobile not available');
    } else {
      amplitude.logEvent('Demo session started');
    }
  }, []);

  return (
    <React.Fragment>
      <Global styles={globalStyle} />
      {isMobile && <UnavailableMobile />}
      {!isMobile && (
        <Layout>
          <WithRouter>
            <Route
              exact
              path='/'
              component={() => <Loading demoURL={process.env.REACT_APP_FRAMER_DEMO_URL} />}
            />
          </WithRouter>
        </Layout>
      )}
    </React.Fragment>
  );
};

export default App;
