import { useState } from 'react';
import useInterval from '~utils/useInterval';

/**
 * useFakeProgress
 * Will return a progress percentage increasing over time
 * @param {Number} initialValue Progress count start value, default 10
 * @param {Number} max          Progress end or max value, default 100
 * @param {Number} speed        How fast progress will increase in miliseconds, default 1000
 */
export const useFakeProgress = (initialValue = 10, max = 100, speed = 1000) => {
  const [progress, setProgress] = useState(initialValue);

  useInterval(
    () => {
      setProgress(progress + 1);
    },
    progress < max ? speed : null,
  );

  return progress;
};
