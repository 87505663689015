import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import Title from '~styled/Title';
import Demo from '~pages/Demo';
import Animation from '~components/Animation';
import ProgressBar from '~components/ProgressBar';
import loading from './loading-data.json';
import { useFakeProgress } from './useFakeProgress';
import { initialMessage, messageDuration, messagesList } from './utils';
import amplitude from '~utils/amplitude';

const Container = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 520px;
  margin: 0 auto;
  margin-top: 3em;
`;

const AnimationContainer = styled.div`
  width: 266px;
  align-self: center;
  margin-bottom: 2em;
`;

const Disclaimer = styled.div`
  color: var(--dark-grey-blue-50);
  padding: 2em;
`;

const WhiteScreenProtector = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  opacity: 0.5;
`;

/**
 * Loading Page
 * Shows an animation and loading message while demo is ready
 * @returns {*} React Component
 */
const Loading = ({ demoURL }) => {
  const [loaded, setLoaded] = useState(false);
  const [message, setMessage] = useState(initialMessage);
  const [counter, setCounter] = useState(-1);
  const fakeDownloadProgress = useFakeProgress(10, 50, 1800);
  const handleOnLoad = counter => {
    const timerId = setTimeout(() => {
      const newMessageIndex = counter + 1;
      // if the list has ended, finish the cycle
      if (typeof messagesList[newMessageIndex] === 'undefined') {
        setLoaded(true);
        amplitude.logEvent('Demo finished loading');
        return clearInterval(timerId);
      }

      // if not, it changes the message and start again
      setCounter(newMessageIndex);
      setMessage(messagesList[newMessageIndex]);
      handleOnLoad(newMessageIndex);
    }, messageDuration);
  };

  useEffect(() => {
    // setting onbeforeunload to any value other than undefined
    // will throw an alert when unloading the window (refresh/close)
    window.onbeforeunload = () => '';

    return () => {
      window.onbeforeunload = undefined;
    };
  }, []);

  const barProgress =
    counter === -1
      ? fakeDownloadProgress
      : // Calculates remaining 50% progress amounts
        Math.floor((50 / messagesList.length) * (counter + 1) + 50);

  return (
    <React.Fragment>
      {!loaded && (
        <Container>
          <Title>Pst! Estamos preparando tu demo...</Title>
          <AnimationContainer>
            <Animation data={loading} />
          </AnimationContainer>

          <ProgressBar message={`${message}...`} progress={barProgress} />
          <Disclaimer>Preparar tu demo podría tardar unos minutos.</Disclaimer>
        </Container>
      )}

      <Demo onLoad={() => handleOnLoad(counter)} show={loaded} demoURL={demoURL} />

      {loaded && (
        <WhiteScreenProtector>Tu demo estará disponible en unos momentos...</WhiteScreenProtector>
      )}
    </React.Fragment>
  );
};

export default Loading;
