import React from 'react';
import styled from '@emotion/styled';

const StyledLogo = styled.img`
  width: 90px;
  height: 57px;
`;

const StyledInterrogation = styled.img`
  width: 225px;
  height: 201px;
`;

const StyledCTA = styled.a`
  width: 126px;
  height: 24px;
  font-family: Proxima Nova Soft;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: var(--white);
  text-transform: uppercase;
  text-decoration: none;
  line-height: 32px;
`;

const StyledCTAContainer = styled.div`
  width: 150px;
  height: 32px;
  border-radius: 24px;
  background-color: #f6a623;
  text-align: center;
`;

const UnavailableLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  min-width: 240px;
  max-width: 240px;
  & p {
    text-align: center;
  }
  & > * {
    margin-top: 32px;
    margin-block-end: 0;
  }
`;

const DarkParagraph = styled.p`
  width: 240px;
  height: 48px;
  margin: 32px 0;
  opacity: 0.5;
  font-family: Proxima Nova Soft;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #352c60;
`;

const TitleParagraph = styled.p`
  width: 240px;
  height: 32px;
  margin: 32px 0;
  font-family: Proxima Nova Soft;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #352c60;
}
`;
/**
 * Demo unavailable page
 * Shows an simple page that informs the user the framer demo is not available on
 * @returns {*} React Component
 */
const UnavailableMobile = () => {
  return (
    <UnavailableLayout>
      <StyledLogo src='/images/runa-logo.png' alt='Runa Logo' />
      <TitleParagraph>Ups! La demo solo esta disponible en versión desktop.</TitleParagraph>
      <StyledInterrogation src='/images/interrogation.png' />
      <DarkParagraph>
        Pst! Usa el link que te hemos enviado a tu correo para acceder al demo desde un desktop.
      </DarkParagraph>
      <StyledCTAContainer>
        <StyledCTA href='https://runahr.com'>Regresar a runa</StyledCTA>
      </StyledCTAContainer>
    </UnavailableLayout>
  );
};

export default UnavailableMobile;
