import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import ErrorMessage from '~styled/ErrorMessage';

const FrameStyled = styled.iframe`
  width: 100%;
  height: calc(100vh - 60px);
  border: none;
  display: ${({ show }) => (show ? 'block' : 'none')};
  z-index: 1;
  position: relative;
`;

/**
 * Demo Page
 * Shows the framer demo
 * @param {Function}  onLoad  onLoad callback for ready event
 * @param {Boolean}   show    Must be true if you want to show iframe contents
 * @returns {*} React Component
 */
const Demo = ({ onLoad, show, demoURL }) => {
  if (!demoURL) {
    onLoad();
    return (
      <ErrorMessage id='errorMessage'>
        Ups! lo sentimos, hubo un error al intentar cargar la configuración de tu demo.
      </ErrorMessage>
    );
  }

  return <FrameStyled id='iframe' src={demoURL} onLoad={onLoad} show={show} />;
};

Demo.defaultProps = {
  show: false,
};

Demo.propTypes = {
  show: PropTypes.bool,
  onLoad: PropTypes.func.isRequired,
  demoURL: PropTypes.string,
};

export default Demo;
