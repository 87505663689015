import React from 'react';
import styled from '@emotion/styled';

import TopBanner from '~components/TopBanner';

const Container = styled.div`
  margin: 0 auto;
  height: 100vh;
  box-sizing: border-box;
  background-color: #f8fafc;
`;

const Body = styled.div``;
const Footer = styled.div``;

const Layout = ({ children }) => (
  <Container>
    <TopBanner />
    <Body id='body'>{children}</Body>
    <Footer />
  </Container>
);

export default Layout;
