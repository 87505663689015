import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'lottie-react-web';

/**
 * Animation
 * Returns an animation with the given animation data.
 * @param   {Object}  data  Javascript JSON data from bodymoving plugin
 * @returns {*}             React Component
 */
const Animation = ({ data }) => (
  <Lottie
    options={{
      animationData: data,
    }}
  />
);

Animation.propTypes = {
  /** Exported dataa from bodymovign after effects plugin */
  data: PropTypes.object.isRequired,
};

export default Animation;
