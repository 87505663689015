const CREATING_COMPANY = 'Creando compañía';
const SIMULATING_EMPLOYEES = 'Simulando empleados';
const GENERATING_PAYROLLS = 'Generando nóminas';
const ASSOCIATING_PERFORMANCE = 'Asociando rendimientos';
const CALCULATING_REPORTS = 'Haciendo reportes';
const SCHEDULING_EVENTS = 'Agendando eventos';
const SETTING_MOVEMENTS = 'Aplicando movimientos';
const LOADING_REQUESTS = 'Cargando solicitudes';
const FINISHING = 'Finalizando';

// Adding more messages will increase loading time,
// each message adds [messageDuration] value in miliseconds
export const messagesList = [
  CREATING_COMPANY,
  SIMULATING_EMPLOYEES,
  GENERATING_PAYROLLS,
  ASSOCIATING_PERFORMANCE,
  CALCULATING_REPORTS,
  SCHEDULING_EVENTS,
  SETTING_MOVEMENTS,
  LOADING_REQUESTS,
  FINISHING,
];

export const messageDuration = 500;
export const initialMessage = 'Descargando archivos del servidor';
