import { useRef, useEffect } from 'react';

/**
 * useInterval
 * Instead of writing code to set and clear the interval,
 * We can declare an interval with a particular delay
 * @param {Function}  callback  Callback function to run on every cicle
 * @param {Number}    delay     Cicle interval delay, set to null if you want to pause interval
 */
const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export default useInterval;
