import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Container = styled.div`
  text-align: left;
  width: 425px;
  margin: 0 auto;
`;

const Message = styled.p`
  font-size: 0.875em;
  margin-bottom: 0.5em;
  color: var(--dark-grey-blue);
`;

const Bar = styled.div`
  width: 100%;
  height: 1em;
  border-radius: 65px;
  background-color: #eae9ef;
  position: relative;
  overflow: hidden;
`;

const Progress = styled.span`
  width: 100%;
  height: 100%;
  background-color: var(--primary);
  border-radius: 65px;
  display: inline-block;
  position: absolute;
  left: ${({ size }) => -100 + size}%;
`;

/**
 * ProgressBar
 * will draw a progress bar with a message and a custom progress %
 * @param   {String}  message   Message to show at the top of the progress bar
 * @param   {Number}  progress  Custom progress, 0-100.
 * @returns {*}                 React component
 */
const ProgressBar = ({ message, progress }) => (
  <Container>
    <Message id='message'>{message}</Message>
    <Bar>
      <Progress id='progress' size={progress} />
    </Bar>
  </Container>
);

ProgressBar.defaultProps = {
  progress: 0,
};

ProgressBar.propTypes = {
  message: PropTypes.string.isRequired,
  progress: PropTypes.number,
};

export default ProgressBar;
