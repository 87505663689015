/**
 * Theme values
 */
export default {
  colors: {
    lightPurple: '#7a6ff0',
    lightPurple05: 'rgba(122, 111, 240, 0.05)',
    lightPurple15: 'rgba(122, 111, 240, 0.15)',
    lightPurple50: 'rgba(122, 111, 240, 0.5)',
    purple: '#665dc9',
    darkGreyBlue: '#352c60',
    darkGreyBlue20: 'rgba(53, 44, 96, 0.2)',
    darkGreyBlue50: 'rgba(53, 44, 96, 0.5)',
    grayBackground: '#f8f8fa',
    yellow: '#F5A623',
    red: '#ff4348',
    redPink: '#ff4383',
    green: '#00e1c8',
    lightGray: '#9b9b9b',
    gray: '#4a4a4a',
    cornflower: '#5a53fd',
    lightBlue: '#02ceff',
    borderColor: '#eaedf2',
    greyBlue: '#7380b1',
    white: '#fff',
  },
  fontWeight: {
    light: 500,
    medium: 700,
    bolder: 900,
  },
  fontSize: {
    xSmall: '0.875rem',
    small: '1rem',
    medium: '1.125rem',
    large: '1.5rem',
    xLarge: '1.875rem',
  },
  spacing: {
    xSmall: '0.5rem',
    small: '1rem',
    medium: '1.5rem',
    large: '2rem',
    xLarge: '5rem',
  },
  borderRadius: {
    standard: '8px',
    pill: '9999px',
  },
  borderWidth: {
    small: '1px',
    medium: '2px',
    large: '4px',
  },
  opacity: {
    light: '24%',
    medium: '40%',
    opaque: '100%',
  },
};
