import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  font-size: 0.875em;
  font-weight: 500;
  color: white;
  padding: 1.5em 1.125em;
  background-color: var(--dark-grey-blue);
  display: grid;
  grid-template-columns: 1fr auto;
`;

const Text = styled.div``;

/**
 * Top Bannner
 * Prints header demo information
 * @returns {*} React component
 */
const TopBanner = () => (
  <Container>
    <Text>
      Bienvenido a la <b>Demo de Runa</b>, descúbre todo lo que puedes hacer.
    </Text>
    <Text>
      Activa tu cuenta al número <b>55 24 56 23 45</b> o escríbenos a <b>info@runahr.com</b>
    </Text>
  </Container>
);

export default TopBanner;
