import amplitude from 'amplitude-js';

const instance = amplitude.getInstance();

instance.init(process.env.REACT_APP_AMPLITUDE_API_KEY, null, {
  // optional configuration options
  saveEvents: true,
  includeUtm: true,
  includeReferrer: true,
});

export default instance;
